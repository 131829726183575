import React, { useState } from "react";
import { render } from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import About from "./About";
import SinglePage from "./SinglePage";
import CardContainer from './CardContainer';
import ActividadesPage from './ActividadesPage';
import ProgramasPage from './ProgramasPage';
import List from "./List";
import CarouselComponent from "./CarouselComponent";
import "./style.css";
import "./bootstrap.css";
import ConvenioPage from "./ConvenioPage";
import InstitucionesPage from "./InstitucionesPage";

function App() {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const handleSelect = () => {
    setExpanded(false); // Collapse the navbar after selecting an item
  };

  const isActive = (path) => location.pathname === path ? "active-link" : "";

  return (
    <div className="app-container">
      <Navbar
        className="custom-navbar"
        expand="lg"
        expanded={expanded} // Bind the expanded state
        onToggle={() => setExpanded(!expanded)} // Toggle state on button click
      >
        <Navbar.Brand as={Link} to="/" onClick={handleSelect}>
          <img src="/fsf.png" alt="Logo" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" onClick={handleSelect} className={isActive("/")}>
              Inicio
            </Nav.Link>
            <Nav.Link as={Link} to="/actividades" onClick={handleSelect} className={isActive("/actividades")}>
              Actividades
            </Nav.Link>
            <Nav.Link as={Link} to="/cardcontainer" onClick={handleSelect} className={isActive("/cardcontainer")}>
              Colaboradores
            </Nav.Link>
            <Nav.Link as={Link} to="/convenios" onClick={handleSelect} className={isActive("/convenios")}>
              Convenios
            </Nav.Link>
            <Nav.Link as={Link} to="/instituciones" onClick={handleSelect} className={isActive("/instituciones")}>
              Instituciones
            </Nav.Link>
            <Nav.Link as={Link} to="/programas" onClick={handleSelect} className={isActive("/programas")}>
              Programas
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link as={Link} to="/article/contacto/" onClick={handleSelect} className={isActive("/article/contacto")}>
              <FontAwesomeIcon icon={faEnvelope} /> <span className="nav-text">Contacto</span>
            </Nav.Link>
            <Nav.Link href="https://www.paypal.com/donate/?hosted_button_id=9E9ZJPQ34AW96" target="_blank" className="paypal-link">
              <FontAwesomeIcon icon={faPaypal} /> <span className="nav-text">Donar</span>
            </Nav.Link>
            <Nav.Link href="https://www.facebook.com/fsfcv" target="_blank" className="social-link">
              <FontAwesomeIcon icon={faFacebook} /> <span className="nav-text">Facebook</span>
            </Nav.Link>
            <Nav.Link href="https://www.youtube.com/channel/UCyc-Tl5uwhfGlfRCRr39cIw" target="_blank" className="social-link">
              <FontAwesomeIcon icon={faYoutube} /> <span className="nav-text">YouTube</span>
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/fsfcomunidadvalenciana/" target="_blank" className="social-link">
              <FontAwesomeIcon icon={faInstagram} /> <span className="nav-text">Instagram</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="container">
        {location.pathname === '/' && <CarouselComponent />}
        <div className="col-12 col-md-12 p-3 p-md-5">
          <div className="row">
            <Route path="/" component={List} exact />
            <Route path="/article/:name" component={SinglePage} />
            <Route path="/about/" component={About} />
            <Route path="/cardcontainer/" component={CardContainer} />
            <Route path="/actividades" component={ActividadesPage} />
            <Route path="/programas" component={ProgramasPage} />
            <Route path="/convenios" component={ConvenioPage} />
            <Route path="/instituciones" component={InstitucionesPage} />
          </div>
        </div>
      </div>
    </div>
  );
}

render(
  <Router>
     <ScrollToTop />
    <App />
  </Router>,
  document.getElementById("root")
);
