// src/components/CardContainer.js
import React from 'react';
import { Link } from 'react-router-dom';

// Define the card styles using inline styles or a CSS-in-JS approach
const cardContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
};

const cardStyle = {
  display: 'block',
  position: 'relative',
  width: '200px',
  height: '150px',
  textDecoration: 'none',
  color: 'inherit',
};

const cardImageStyle = {
  width: '100%',
  height: '100%',
  display: 'block',
};

const cardOverlayStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '0',
  transition: 'opacity 0.3s ease',
};

const cardTextStyle = {
  color: 'white',
  fontSize: '1.2rem',
  textAlign: 'center',
};

const cardData = [
  { image: '/article-assets/colaboraciones/aecc.png', link: 'https://www.contraelcancer.es/es', alt: 'AECC' },
  { image: '/article-assets/colaboraciones/afa-alicante.jpg', link: 'https://www.alzheimeralicante.org/co-financiadores/', alt: 'AFA Alicante' },
  { image: '/article-assets/colaboraciones/alguibama.jpg', link: 'https://alguibama.org/colabora-con-nosotros/', alt: 'Alguibama' },
  { image: '/article-assets/colaboraciones/ayuntamientoalicante.png', link: 'https://www.alicante.es/es', alt: 'Ayuntamiento Alicante' },
  { image: '/article-assets/colaboraciones/caritas.png', link: 'https://www.caritas.es', alt: 'Caritas' },
  { image: '/article-assets/colaboraciones/cecova.jpg', link: 'https://portalcecova.es/', alt: 'CECOVA' },
  { image: '/article-assets/colaboraciones/corte-ingles.png', link: 'https://www.ambitocultural.es/conocenos/', alt: 'Corte Ingles' },
  { image: '/article-assets/colaboraciones/diputacion-alicante.jpg', link: 'https://www.diputacionalicante.es', alt: 'Diputacion Alicante' },
  { image: '/article-assets/colaboraciones/tutela.png', link: 'https://www.ftutelacv.com/', alt: 'Tutela' },
];

const CardContainer = () => {
  return (
    <div style={cardContainerStyle}>
      {cardData.map((card, index) => (
        <a
          key={index}
          href={card.link}
          target="_blank"
          rel="noopener noreferrer"
          style={cardStyle}
          onMouseEnter={(e) => (e.currentTarget.querySelector('.card-overlay').style.opacity = '1')}
          onMouseLeave={(e) => (e.currentTarget.querySelector('.card-overlay').style.opacity = '0')}
        >
          <img src={card.image} alt={card.alt} style={cardImageStyle} />
          <div style={cardOverlayStyle} className="card-overlay">
            <span style={cardTextStyle}>Visitar Página</span>
          </div>
        </a>
      ))}
    </div>
  );
};

export default CardContainer;
