import React from "react";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <h1>Bros</h1>
      <h3>Metal</h3>
    </>
  );
};
export default About;
