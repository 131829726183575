import React from "react";
import ArticleContent from "./ArticleContent";
import ReactHtmlParser from "react-html-parser";

const SinglePage = ({ match }) => {
  const name = match.params.name;
  const article = ArticleContent.find(article => article.name === name);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12 p-2">
          <div className="shadow rounded p-3 p-md-4">
            <h1>
              <strong>{article.title}</strong>
            </h1>
            {article.content.map((paragraph, key) => (
              <div className="lead" key={key}>
                {ReactHtmlParser(paragraph)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePage;
