import React from 'react';

const InstitucionesPage = () => {
  const content = {
    name: "modx",
    title: "Instituciones",
    desc: "Revolusi dan evolusi bersama MODX",
    content: [
      "<p>Se han establecido distintas relaciones con instituciones tales como:</p>",
      "<ul><li>Asociación de Jubilados</li><li>Titulados de Enfermería</li><li>Concejalía de acción social del Ayuntamiento de Alicante</li><li>Voluntarios del hospital de Alicante</li><li>Voluntarios del hospital de San Vicente</li><li>Voluntarios niños con cáncer</li><li>Fundación pro-tutela</li><li>Teléfono de la Esperanza</li><li>Asoka el grande</li><li>Radio María</li><li>AFA Alicante - Asociación de familiares y amigos de enfermos de Alzheimer de Alicante</li><li>Asociación Española contra el Cáncer</li><li>Caritas</li><li>Asociación de Ayuda al pueblo Saharaui de Elche</li><li>ONG Alguibama</li></ul>",
      "<p>Especialmente se ha de destacar el apoyo de la Excma. Diputación de Alicante – Área de Bienestar Social y Área de Cultura que han participado entre otras cosas con una importante subvención para material informático.</p>",
    ],
  };

  return (
    <div className="instituciones-container">
      <h1>{content.title}</h1>
      <p>{content.desc}</p>
      <div className="instituciones-content">
        {content.content.map((section, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: section }} />
        ))}
      </div>
    </div>
  );
};

export default InstitucionesPage;
