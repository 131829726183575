import React from "react";
import { Link } from "react-router-dom";

function ProgramasPage() {
  return (
    <div className="programas-page">
      <h1>Programas</h1>
      <p>Explore the various programs offered by our organization:</p>
      <ul>
        <li>
          <Link to="/article/programas-formacion-familiar">Programas de Formación Familiar</Link>
        </li>
        <li>
          <Link to="/article/programa-sensibilizacion">Programa de Sensibilización</Link>
        </li>
        <li>
          <Link to="/article/programa-hominum">Programa Hominum</Link>
        </li>
        <li>
          <Link to="/article/programa-comunicar-ideas">Programa Comunicar Ideas</Link>
        </li>
        <li>
          <Link to="/article/proyecto-aula-de-familia">Proyecto Aula de Familia</Link>
        </li>
        <li>
          <Link to="/article/proyecto-mediacion-familiar">Proyecto de Mediación Familiar</Link>
        </li>
        <li>
          <Link to="/article/solidaridad">Solidaridad</Link>
        </li>
      </ul>
    </div>
  );
}

export default ProgramasPage;
