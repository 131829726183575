import React from 'react';

const ConvenioPage = () => {
  const content = {
    name: "convenio",
    title: "Convenio de Colaboración",
    desc: "Acuerdo entre la Asociación Pro Vida Humana de Alicante y la Fundación de Servicios Familiares de la Comunidad Valenciana",
    content: [
      "<h2>Convenio de Colaboración</h2>",
      "<p><strong>Entre la Asociación Pro Vida Humana de Alicante</strong> (PROVIDA ALICANTE) y la <strong>Fundación de Servicios Familiares de la Comunidad Valenciana</strong> (CENTRO HOMINUM)</p>",
      "<p><em>Alicante, 1 de marzo de 2021</em></p>",
      "<h2>Partes Firmantes</h2>",
      "<p>De una parte, Don José Manuel Grau Delgado, con DNI nº 21.480.877-G, Presidente de la Asociación Pro Vida Humana de Alicante, actuando en nombre y representación de la misma, con domicilio en 03001-Alicante, C/ Médico Pascual Pérez, nº 4, 2ª Izqda., y CIF G-03210481.</p>",
      "<p>De otra parte, Don José Gabriel Alfonso Pérez con DNI nº 21.961.199-V, Apoderado de la Fundación de Servicios Familiares de la Comunidad Valenciana, actuando en nombre y representación de la misma, con domicilio en 03003-Alicante, C/ Pintor Cabrera, nº 8, 4ºA, y CIF G-54115365.</p>",
      "<h2>Exposición de Motivos</h2>",
      "<p><strong>Primero:</strong> La Asociación Provida Alicante tiene como objetivo principal la defensa de la vida desde el momento de la concepción hasta el fallecimiento, respetando y promoviendo la dignidad humana durante todo el ciclo vital.</p>",
      "<p><strong>Segundo:</strong> La Fundación Hominum se dedica a proporcionar servicios asistenciales y formación adecuada a las familias para contribuir a su estabilidad y desarrollo, promoviendo así la dignidad de cada uno de sus miembros.</p>",
      "<p><strong>Tercero:</strong> Ambas entidades coinciden en la importancia de realizar y promocionar actividades que mejoren la calidad de vida de las personas, especialmente de las madres en periodo gestacional o con niños pequeños, así como proteger a las personas gravemente enfermas y/o de avanzada edad, brindándoles el apoyo necesario.</p>",
      "<h2>Cláusulas del Convenio</h2>",
      "<p><strong>Primera:</strong> La finalidad del Convenio es apoyarse mutuamente en las iniciativas y proyectos conjuntos, con el objetivo de proteger la dignidad humana y la familia, especialmente de enfermos y ancianos, y facilitar la integración de mujeres en riesgo de exclusión social por embarazo o maternidad.</p>",
      "<p><strong>Segunda:</strong> Las formas de colaboración incluirán:</p>",
      "<ul>",
      "<li>Colaborar en red con otras asociaciones, fundaciones o entidades públicas y privadas para ejecutar programas subvencionados.</li>",
      "<li>Dar publicidad a las actuaciones y actividades de la otra entidad.</li>",
      "<li>Organizar cursos, conferencias, charlas formativas, eventos lúdicos y benéficos, entre otros.</li>",
      "<li>Concurrir a programas subvencionables para los colectivos que protegen ambas asociaciones.</li>",
      "<li>Cualquier otra forma de colaboración que sea beneficiosa para lograr los objetivos de ambas asociaciones.</li>",
      "</ul>",
      "<p><strong>Tercera:</strong> Las actividades se realizarán en la medida de las posibilidades de cada asociación.</p>",
      "<p><strong>Cuarta:</strong> El Convenio tendrá una duración de un año a partir de la firma del mismo, con posibilidad de prórroga automática por otro año si no se notifica la intención de rescisión con dos meses de antelación. También podrá ser rescindido en cualquier momento por acuerdo mutuo.</p>",
      "<p>En prueba de conformidad, firman el presente documento en la ciudad y fecha arriba indicados.</p>",
      "<p>Fdo. José Manuel Grau Delgado - Presidente Provida Alicante</p>",
      "<p>Fdo. José Gabriel Alfonso Pérez - Apoderado F.S.F de la CV</p>",
    ],
  };

  return (
    <div className="convenio-container">
      <h1>{content.title}</h1>
      <p>{content.desc}</p>
      <div className="convenio-content">
        {content.content.map((section, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: section }} />
        ))}
      </div>
    </div>
  );
};

export default ConvenioPage;
