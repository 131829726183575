import React from "react";
import { Carousel } from "react-bootstrap";
import './CarouselComponent.css'; // Import the custom CSS file
import { Link } from "react-router-dom";

const CarouselComponent = () => {
  return (
    <Carousel className="custom-carousel carousel-fade" interval={25000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('./assets/images/bannerimg2.jpg')}
          alt="Primera diapositiva"
        />
        <Carousel.Caption>
        <Link to="/actividades" className="carousel-link">
            <p>Nuestras Actividades</p>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('./assets/images/bannerimg3.jpg')}
          alt="Segunda diapositiva"
        />
        <Carousel.Caption>
        <Link to="/article/programas-formacion-familiar" className="carousel-link">
            <p>Programas de la Fundación</p>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
