import { Link } from "react-router-dom";

function ActividadesPage() {
  return (
    <div className="actividades-page">
      <h2>Actividades</h2>
      <ul>
        <li>
          <Link to="/article/memoria-2020-fsf">
            Memoria 2020
          </Link>
        </li>
        <li>
          <Link to="/article/actos-marzo-ua-alicante">
            Programadas
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default ActividadesPage;
