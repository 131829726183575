import React from "react";
import { Link } from "react-router-dom";

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const ArticleList = ({ articles }) => (
  <div className="d-flex flex-wrap justify-content-center">
    {articles.map((article, key) => (
      <Link
        className="btn btn-outline-info rounded border border-white p-3 p-md-5 col-12 col-md-4"
        key={key}
        to={`/article/${article.name}`}
      >
        <h3>
          <strong>{article.title}</strong>
        </h3>
        <p>
          {stripHtmlTags(article.content[0]).substring(0, 95)}...
          <br />
          Seguir leyendo →
        </p>
      </Link>
    ))}
  </div>
);

export default ArticleList;
